import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { Accordion, SendEnquiryBlock } from '/src/components/common'

const PrivacyPolicyPage = () => {
  return (
    <Layout hero={<HeroImage title="Privacy Policy" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center mt-5">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <p>
              While Orrcon Steel has endeavoured to ensure that all information provided on Orrcon Steel Internet websites is accurate and up to date, 
              Orrcon Steel takes no responsibility for any error or omission relating to this information. 
              To the maximum extent permitted by law, Orrcon Steel will not be liable for any cost, 
              loss or damage suffered by you through your use of Orrcon Steel's Internet websites or 
              Orrcon Steel's failure to provide access to these Internet websites or information updates. 
              Orrcon Steel is a wholly owned subsidiary of Bluescope Steel.
              </p>
              <p>Your use of the website is governed by the BlueScope Steel website Terms and Conditions of Use.</p>
              <p>The content of this Privacy Policy may be updated from time to time, so we suggest that you return to the website on a regular basis and carefully read the information provided.</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={12}>
              <Accordion items={accordionItems} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default PrivacyPolicyPage

export const Head = () => (
  <>
    <title>Privacy Policy | Orrcon Steel</title>
    <meta name="description" content="Orrcon Steel is a leading Australian distributor and manufacturer of steel, tube and pipe. Our extensive product range covers RHS, SHS & CHS structural tubular steel, hot rolled structural steel and a variety of fencing, roofing and building accessories." />
    <meta name="og:description" property="og:description" content="Your One-Stop Shop for Effortless Ordering of Premium Steel Products. Buy online or call us today. At Orrcon Steel, we'll see it through" />
    <meta property="og:image" content="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/og_orrcon.png" />
  </>
)

const accordionItems = [
  {
    question: 'Collection of personal information',
    answer: `
      <ul>
        <li>The types of personal information BlueScope Steel will collect from you will depend on the circumstances in which that information is collected. It may include: your name, date of birth, contact details such as current and previous address, telephone numbers, email address, occupation, details of shareholdings, information required for recruitment purposes, information required to open or maintain a trading account with BlueScope or otherwise do business with us, including your bank account details, information obtained through credit checks, information from prior dealings with BlueScope and statistical information on the use of the BlueScope Steel websites, such as your IP address, dates and times of visits.</li>
        <li>We collect personal information through various means including: our websites, use of social media, orders for and provision of products, services or support, employment applications, third party service providers, credit checks, credit assessments and debt recovery actions, warranty claims, feedback from customers, our shareholder registry, responses to surveys or research conducted by us, and entries into competitions or trade promotions.</li>
        <li>If you do not provide us with the information we request, we may not be able to fulfill the applicable purpose of collection, such as to supply products or services to you or to assess your application for employment.</li>
        <li>Where practicable, we will collect personal information directly from you. If we receive information about you from someone else (for example from someone who supplies goods or services to us), we will take reasonable steps to ensure you are aware that we have collected personal information about you and the circumstances of the collection.</li>
      </ul>
    `
  },
  {
    question: 'Use and disclosure of personal information',
    answer: `
      <ul>
        <li>Our main purposes for collecting, holding, using and disclosing personal information are to: supply and obtain products or services; respond to enquiries; assess and process warranty claims; assess employment applications; undertake research and surveys, and analyse statistical information; conduct trade promotions; comply with legislation and regulations; comply with our policy requirements including in relation to occupational health and safety, diversity, workers compensation, Fair Work Act and environmental matters; to inform you of changes to our business and our products; and for marketing purposes, including to assist in developing our website.</li>
        <li>We generally explain at the time we collect personal information how we will use or disclose that information. We will only use or disclose personal information for a purpose other than for which it was collected or a related purpose if you have consented to such different use or disclosure or such use or disclosure is otherwise allowed by the Privacy Laws.</li>
        <li>In carrying out our business, it may be necessary to share information about you with and between our related bodies corporate and organisations that provide services to us (eg, our alliance partners). </li>
      </ul>
    `
  },
  {
    question: 'Disclosure of information outside the jurisdiction of collection',
    answer: `
      <p>We may disclose personal information collected in Australia outside of the jurisdiction. In the conduct of our business, we transfer to, hold or access personal information from various countries outside Australia including New Zealand, China, North America and India.</p>
    `
  },
  {
    question: 'Direct marketing',
    answer: `
      <p>We may, from time to time, send you marketing materials. If you are receiving promotional information from us and do not wish to receive this information any longer, please contact BlueScope Steel direct privacyquestions@bluescope.com asking to be removed from our mailing lists, or use the unsubscribe facilities included in our marketing communications.</p>
    `
  },
  {
    question: 'Our website privacy practices',
    answer: `
      <p>We sometimes use cookie technology on our websites to provide information and services to web site visitors. Cookies are pieces of information that a website transfers to your computer's hard disk for record keeping purposes and are a necessary part of facilitating online transactions. Most web browsers are set to accept cookies. Cookies are useful to estimate our number of members and determine overall traffic patterns through our websites. If you do not wish to receive any cookies you may set your browser to refuse cookies. This may mean you will not be able to take full advantage of the services on the website.</p>
    `
  },
  {
    question: 'Links to other websites',
    answer: `
      <p>Our websites may contain links to third party websites. These linked sites are not under our control and we are not responsible for the content of those sites nor are those sites subject to our Privacy Policy. Before disclosing your personal information on any other website we recommend that you examine the terms and conditions and Privacy Policy of the relevant site. BlueScope Steel is not responsible for any practices on linked websites that might breach your privacy.</p>
    `
  },
  {
    question: 'Accessing and correcting the information we keep about you',
    answer: `
      <p>If at any time you want to know exactly what personal information we hold about you, you are welcome to request access to your record by contacting us at privacyquestions@bluescope.com. Our file of your information will usually be made available to you within 21 days.</p>
      <p>If at any time you wish to change personal information that we hold about you because it is inaccurate or out of date, please contact us at privacyquestions@bluescope.comand we will amend this record. If you wish to have your personal information deleted, please let us know in the same manner as referred to above and we will take all reasonable steps to delete it unless we need to keep it for legal reasons.</p>
    `
  },
  {
    question: 'Contacting us',
    answer: `
      <ul>
        <li>If you have any concerns or complaints about how we handle your personal information, or if you have any questions about this policy, please contact us at privacyquestions@bluescope.com. </li>
        <li>In most cases we will ask that you put your request in writing to us. We will investigate your complaint and will use reasonable endeavours to respond to you in writing within 30 days of receiving the written complaint. If we fail to respond to your complaint within 30 days of receiving it in writing or if you are dissatisfied with the response that you receive from us, you may have the right to make a complaint to the Office of the Information Commissioner.</li>
      </ul>
    `
  },
  {
    question: 'Future changes',
    answer: `
      <p>We reserve the right to change this Privacy Policy at any time and notify you by posting an updated version of the policy on our website. If at any point we decide to use personal information in a manner materially different from that stated at the time it was collected we will notify you by email or via a prominent notice on our website, and where necessary we will seek your prior consent.</p>
    `
  },
  {
    question: 'Other information',
    answer: `
      <p>This Privacy Policy applies to BlueScope Steel Limited ("we", "us", "our") and its affiliates in Australia in relation to their operations and explains how we handle personal information and comply with the requirements of applicable privacy laws ("Privacy Laws"). If you have further questions relating to this policy please contact privacyquestions@bluescope.com.</p>
    `
  }
]
